<template>
	<Spiner />
</template>

<script>
import Spiner from '../components/UI/Spinner.vue';
import appUrl from '../store/appUrl';
export default {
	components: {
		Spiner,
	},

	async created() {
		let postValue = {};
		if (localStorage.isLogin) {
			postValue = {
				code: this.$route.query.code,
				auth: 'login',
			};
			localStorage.removeItem('isLogin');
		} else {
			postValue = {
				code: this.$route.query.code,
			};
		}
		try {
			let response = await this.$http.post('auth/register/facebook', postValue);
			localStorage.user_token = response.data.token;
			if (response.data.img) {
				window.location.replace(appUrl + `?token=${response.data.token}&img=${response.data.img}`);
			} else {
				window.location.replace(appUrl + `?token=${response.data.token}`);
			}
		} catch (error) {
			if (error.data.message === 'User not found') {
				alert('User is not registered');
				this.$router.push({ name: 'main' });
			}
		}
	},
};
</script>

<style>
</style>